import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useCourseSettingList() {
  //User toast
  const toast = useToast();

  const refCourseSettingTable = ref(null);

  const tableColumns = [
    { key: "id", sortable: true },
    { key: "name", stickyColumn: true },
    { key: "description" },
    { key: "is_active" },
    { key: "action" },
  ];

  const perPage = ref(10);
  const totalCourseSetting = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refCourseSettingTable.value
      ? refCourseSettingTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCourseSetting.value,
    };
  });

  const refetchData = () => {
    refCourseSettingTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery], () => {
    refetchData();
  });

  const fetchCourseSetting = (ctx, callback) => {
    store
      .dispatch("course-setting/fetchOrganiser", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then((response) => {
        console.log(response);
        const { course_organization, total } = response.data;
        callback(course_organization);
        totalCourseSetting.value = total;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching certification codes list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchCourseSetting,
    tableColumns,
    perPage,
    currentPage,
    totalCourseSetting,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCourseSettingTable,
    ability,
    refetchData,
  };
}
