import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOrganiser(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/course/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateOrganiser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("course", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteOrganiser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`course?id=${data.id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
